<template>
  <uspCartao 
    titulo="Ações" 
    :fechavel="false"
    :classes="{ 'card': 'sticky-top', 'cardBody': 'border rounded' }"
  >
    <template #corpo>
      <ProcessosAvisoTutorial />
      <div class="container-fluid container-acoes">
        <uspSkeleton v-if="carregando">
          <div>
            <div class="w-40" />
            <div class="w-30" />
            <div class="w-15" />
            <div class="w-15" />
          </div>
        </uspSkeleton>
        <div
          v-if="!carregando"
          class="acoes btn-group"
          role="group"
          aria-label="Ações de Processo"
        >
          <uspDropdown
            v-if="permissoes.andamento === 'S'"
            id="acoes-incluir-dropdown"
            variante="sucesso"
            texto="Incluir"
            icone="fas fa-plus"
          >
            <template #itens>
              <a 
                class="dropdown-item informacao"
                href="#"
                @click.prevent="criarInformacao()"
              >
                <em class="fas fa-edit" />
                Incluir Informação
              </a>
              <a 
                class="dropdown-item anexar"
                href="#"
                @click.prevent="anexarArquivos()"
              >
                <em class="fas fa-paperclip" />
                Incluir Anexos
              </a>
              <a 
                class="dropdown-item gerar-doc"
                href="#"
                @click.prevent="criarGeracaoDocumento()"
              >
                <em class="fas fa-file-invoice" />
                Incluir Documento Gerado
              </a>
            </template>
          </uspDropdown>
          <uspDropdown
            v-if="permissoes.saida === 'S'"
            id="acoes-tramitar-dropdown"
            variante="primario"
            texto="Tramitar"
            icone="fas fa-file-import"
          >
            <template #itens>
              <a 
                v-if="permissoes.andamento === 'S' && permissoes.saida === 'S'"
                class="dropdown-item saida"
                href="#"
                @click.prevent="abrirModal('saida')"
              >
                <em class="fas fa-file-import" />
                Dar Saída
              </a>
              <a 
                v-if="permissoes.andamento === 'N' && permissoes.saida === 'S'"
                class="dropdown-item cancelar-saida"
                href="#"
                @click.prevent="abrirModal('cancelar')"
              >
                <em class="fas fa-times" />
                Cancelar Saída
              </a>
              <a 
                v-if="devolver"
                class="dropdown-item devolver"
                href="#"
                @click.prevent="abrirModal('devolver')"
              >
                <em class="fas fa-undo" />
                Devolver Processo
              </a>
            </template>
          </uspDropdown>
          <uspDropdown
            v-if="verAcoes"
            id="acoes-acoes-dropdown"
            variante="secundario"
            texto="Ações"
            alinhamento="direita"
            icone="fas fa-folder"
          >
            <template #itens>
              <a 
                v-if="podeLer"
                :href="pdfUrl"
                target="_blank"
                class="dropdown-item ler-pdf"
              >
                <em class="fas fa-file-pdf" />
                Ler em PDF
              </a>
              <a 
                v-if="podeLer"
                :href="bookUrl"
                target="_blank"
                class="dropdown-item ler-livreto"
              >
                <em class="fas fa-book-reader" />
                Ler em Revista
              </a>
              <router-link
                v-if="podeAlterar"
                :to="{ name: 'Processos:Alterar', props: { numpro } }"
                class="dropdown-item alterar"
              >
                <em class="fas fa-edit" />
                Alterar Dados
              </router-link>
              <a 
                v-if="permissoes.entrada === 'S'"
                class="dropdown-item entrada"
                href="#"
                @click.prevent="abrirModal('entrada')"
              >
                <em class="fas fa-check-circle" />
                Dar Entrada
              </a>
              <a 
                v-if="permissoes.arquivar === 'S'"
                class="dropdown-item arquivar"
                href="#"
                @click.prevent="abrirModal('arquivar')"
              >
                <em class="fas fa-archive" />
                Arquivar Processo
              </a>
              <a 
                v-if="permissoes.desarquivar === 'S'"
                class="dropdown-item desarquivar"
                href="#"
                @click.prevent="abrirModal('desarquivar')"
              >
                <em class="fas fa-box-open" />
                Desarquivar Processo
              </a>
            </template>
          </uspDropdown>
        </div>
        <input
          id="despacho-arquivo"
          type="file"
          name="arquivo"
          :accept="accept"
          multiple
          hidden
          @change="carregarArquivos($event.target.files)"
        >
      </div>
    </template>
  </uspCartao>
</template>

<script>
import { formataProcesso } from '@/utils';
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { Actions, Getters, Mutations } from '@/processos/store/types'
import ProcessosAvisoTutorial from '../components/ProcessosAvisoTutorial.vue'
export default {
    name: 'ProcessosCartaoAcoes',
    components: { ProcessosAvisoTutorial },
    props:{
      numpro:{
        type: String,
        required: true,
      }
    },
    data() {
      return {
        abrirAnexo: false,
        baseUrl: window.location.origin
      };
    },
    computed: {
        ...mapGetters({
            accept: Getters.ANDAMENTOS.EXTENCOES_ACEITAS,
            carregando: Getters.PROCESSOS.OBTENDO_ACOES,
            devolver: Getters.PROCESSOS.DEVOLVER,
            permissoes: Getters.PROCESSOS.ACOES
        }),
        bookUrl(){
          const api = `/proxy/wsprocesso/api/processo/${this.numpro}/pdf?modoView=inline`;
          return `${this.baseUrl}/wsprocesso/viewer/viewer.html?file=${api}`;
        },
        numprofmt(){
            return formataProcesso(this.numpro)
        },
        pdfUrl(){
          return `${this.baseUrl}/proxy/wsprocesso/api/processo/${this.numpro}/pdf?modoView=inline`;
        },
        podeAlterar(){
            return this.permissoes.alterar === 'S'
        },
        podeLer(){
            return this.permissoes.consultar === 'S'
        },
        verAcoes(){
          return this.permissoes.consultar === 'S' || this.permissoes.arquivar === 'S' ||
                 this.permissoes.desarquivar === 'S' || this.permissoes.alterar === 'S' || 
                 this.permissoes.entrada === 'S'

        }
    },
    mounted() {
        this.carregar({ numpro: this.numpro });
    },
    methods: {
        ...mapActions({
            carregarArquivos: Actions.ANDAMENTOS.CARREGAR_ARQUIVO,
            carregar: Actions.PROCESSOS.CARREGAR_ACOES,
            criarInformacao: Actions.ANDAMENTOS.CRIAR_INFORMACAO,
            criarGeracaoDocumento: Actions.ANDAMENTOS.CRIAR_DOCUMENTO
        }),
        ...mapMutations({
            criarNovaOcorrencia: Mutations.ANDAMENTOS.NOVA_OCORRENCIA
        }),
        abrirModal(tipo){
            this.$emit('abrir-modal', tipo)
        },
        anexarArquivos(){
            let $anexo = document.getElementById('despacho-arquivo')
            if($anexo) $anexo.click()
        },
    },
}
</script>

<style lang="scss" scoped>

    div.container-acoes {
        display: flex;
        justify-content: center;
    }

    div.acoes.btn-group {

        ::v-deep a.btn {
            margin-left: 0 !important;
            margin-right: 0 !important;
        }

        ::v-deep div.dropdown:not(:last-child):not(:first-child) a.btn {
            border-radius: 0px !important;
        }

        ::v-deep div.dropdown:first-child:not(:only-child) a.btn {
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }

        ::v-deep div.dropdown:last-child:not(:only-child) a.btn {
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
        }

        ::v-deep a.dropdown-item {
            align-items: center;
            display: flex;

            .fas {
                width: 1rem;
                margin-right: 0.3em;
            }
        }
    }

    

    strong.label {
        color: #1094ab
    }

</style>