<template>
  <section>
    <div class="container-fluid">
      <ProcessosCartaoDados :numpro="numpro" />
      <ProcessosCartaoAcoes 
        :numpro="numpro"
        @abrir-modal="abrirModal"
      />
      <ProcessosCartaoJuntados 
        :numpro="numpro"
      />
      <ProcessosCartaoArvoreArquivos :numpro="numpro" />
      <ProcessosCartaoAndamentos :numpro="numpro" />
    </div>
    <portal selector="#portal-target">
      <ProcessosModalConfirmar />
      <ProcessosModalArquivar
        :mostrar="modal === 'arquivar'"
        @fechar="modal = ''"
      />
      <ProcessosModalAssinar />
      <ProcessosModalCancelarSaida
        :mostrar="modal === 'cancelar'"
        @fechar="modal = ''"
      />
      <ProcessosModalDesarquivar
        :mostrar="modal === 'desarquivar'"
        @fechar="modal = ''"
      />
      <ProcessosModalDevolver
        :mostrar="modal === 'devolver'"
        @fechar="modal = ''"
      />
      <ProcessosModalEntrada 
        :mostrar="modal === 'entrada'"
        @fechar="modal = ''"
      />
      <ProcessosModalSaida 
        :mostrar="modal === 'saida'"
        @fechar="modal = ''"
      />
      <ProcessosModalAjudaTipoConf
        :mostrar="modal === 'ajuda-tipo-conf'"
        @fechar="modal = ''"
      />
    </portal>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import { formataProcesso } from '@/utils'
import { Getters } from '@/processos/store/types'
import ProcessosCartaoAcoes from '@/processos/components/ProcessosCartaoAcoes.vue'
import ProcessosCartaoAndamentos from '@/processos/components/ProcessosCartaoAndamentos.vue'
import ProcessosCartaoArvoreArquivos from '@/processos/components/ProcessosCartaoArvoreArquivos.vue'
import ProcessosCartaoDados from '@/processos/components/ProcessosCartaoDados.vue'
import ProcessosCartaoJuntados from '@/processos/components/ProcessosCartaoJuntados.vue'
import ProcessosModalAjudaTipoConf from '../components/modais/ProcessosModalAjudaTipoConf.vue'
import ProcessosModalAssinar from '../components/modais/ProcessosModalAssinar.vue'
import ProcessosModalCancelarSaida from '../components/modais/ProcessosModalCancelarSaida.vue'
import ProcessosModalConfirmar from '../components/modais/ProcessosModalConfirmar.vue'
import ProcessosModalDevolver from '../components/modais/ProcessosModalDevolver.vue'
import ProcessosModalEntrada from '../components/modais/ProcessosModalEntrada.vue'
import ProcessosModalSaida from '../components/modais/ProcessosModalSaida.vue'
import ProcessosModalArquivar from '../components/modais/ProcessosModalArquivar.vue'
import ProcessosModalDesarquivar from '../components/modais/ProcessosModalDesarquivar.vue'

export default {
  name: 'ProcessosAcompanhamento',

  components:{
    ProcessosCartaoAcoes,
    ProcessosCartaoAndamentos,
    ProcessosCartaoArvoreArquivos,
    ProcessosCartaoDados,
    ProcessosCartaoJuntados,
    ProcessosModalAjudaTipoConf,
    ProcessosModalArquivar,
    ProcessosModalAssinar,
    ProcessosModalCancelarSaida,
    ProcessosModalConfirmar,
    ProcessosModalDevolver,
    ProcessosModalEntrada,
    ProcessosModalSaida,
    ProcessosModalDesarquivar
},

  beforeRouteLeave(to, from, next){
    if (this.statusEdicao){
        if (!window.confirm("Deseja sair sem salvar efetivamente as ocorrências em rascunho?")) {
          return;
        }
      }
      next();
  },

  data(){
    return {
      ajuda: false,
      aviso: '',
      modal: '',
      numpro: this.$route.params.numpro
    }
  },

  computed:{
    ...mapGetters({
      statusEdicao: Getters.ANDAMENTOS.STATUS_EDICAO
    }),
    numprofmt(){
      return formataProcesso(this.numpro)
    },
  },

  methods:{
    abrirModal(tipo){
      this.modal = tipo
    },
  }
}
</script>

<style lang="scss">
@import "./src/componentes/estilos/temas.scss";

.usp-resultados .conteudo {
  flex-wrap: wrap;
  flex-basis: 100% !important;

  > div {
    width: 100%;
  }

}

</style>