<template>
  <uspAlerta
    :variante="variante"
  >
    <em class="fas fa-info-circle" />
    Precisa de ajuda para utilizar todas as funcionalidades nos Processos Digitais? 
    Acesse nosso guia do usuário 
    <a 
      :href="linkTutorial" 
      :target="linkTarget"
    >
      aqui
    </a>.
  </uspAlerta>
</template>

<script>
  export default {
    name: 'ProcessosAvisoTutorial',

    data(){
      return {
        linkTutorial: 'https://uspdigital.usp.br/comumwebdev/arquivos/portal/Processos%20Digitais.pdf',
        linkTarget: '_blank',
        variante: 'secundario'
      }
    }
  }
</script>

<style lang="scss" scoped>
  .alert {
    margin-bottom: 0;
  }

  a {
    color: #0B2000
  }
</style>