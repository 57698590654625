<template>
  <section>
    <div class="container-fluid">
      <uspCartao :titulo="titulo">
        <template #corpo>
          <ProcessosAvisoTutorial />
          <uspAlerta 
            v-show="!!erro" 
            ref="alertaSalvar" 
            variante="perigo"
            fechavel 
            @fechar="limpaErro()"
          >
            {{ erro }}
          </uspAlerta>
          <uspSkeleton v-if="carregando">
            <div>
              <div class="w-50" />
              <div class="w-50" />
            </div>
            <div>
              <div class="w-50" />
              <div class="w-50" />
            </div>
            <div>
              <div class="w-50" />
              <div class="w-50" />
            </div>  
          </uspSkeleton>
          <form 
            v-show="!carregando"
            novalidate
            :class="{ 'was-validated': validado }"
            @submit.prevent="salvar"
          >
            <div class="form-row">
              <div class="form-group col-sm">
                <label 
                  for="nominr" 
                  class="control-label"
                >
                  Interessado
                </label>
                <input
                  id="nominr"
                  v-model="nominr"
                  type="text"
                  class="form-control"
                  name="nominr"
                  maxlength="255"
                  required
                >
                <span 
                  class="invalid-feedback"
                >
                  {{ erros.nominr }}
                </span>
              </div>
              <div class="form-group col-sm">
                <label 
                  for="titpro"
                  class="control-label"
                >
                  Doc. Base
                </label>
                <input
                  id="titpro"
                  v-model="titpro"
                  type="text"
                  class="form-control"
                  name="titpro"
                  maxlength="255"
                  required
                >
                <span class="invalid-feedback">{{ erros.titpro }}</span>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm">
                <label 
                  for="codasu"
                  class="control-label"
                >
                  Assunto
                </label>
                <usp-autocompletar 
                  :id="'codasu'"
                  v-model="codasu"
                  :name="'codasu'"
                  :itens="assuntos"
                  :inicial="codasuInicial"
                  :erro-validacao="erros.codasu"
                  obrigatorio
                  placeholder="Digite para selecionar o assunto..."
                />
              </div>
              <div class="form-group col-sm">
                <label 
                  for="sgletaabt"
                  class="control-label"
                >
                  Setor de Entrada
                </label>
                <select
                  id="sgletaabt"
                  v-model="sgletaabt"
                  class="form-control"
                  required
                >
                  <option value="">
                    - Selecione -
                  </option>
                  <option 
                    v-for="(estacao, idx) in estacoes"
                    :key="estacao.getCodigo()"
                    :value="estacao.getCodigo()"
                    :selected="idx == 0"
                  >
                    {{ estacao.getTexto() }}
                  </option>
                </select>
                <span class="invalid-feedback">{{ erros.sgletaabt }}</span>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col">
                <label 
                  for="cplasu"
                  class="control-label"
                >
                  {{ cplasu_label }}
                </label>
                <input
                  id="cplasu"
                  v-model="cplasu"
                  name="cplasu"
                  class="form-control"
                  :required="cplobg"
                  maxlength="240"
                >
                <span class="invalid-feedback">{{ erros.cplasu }}</span>
              </div>
            </div>
            <div class="acoes d-flex justify-content-end">
              <uspBotao 
                type="submit"
                variante="sucesso"
                icone="fas fa-save"
                texto="Salvar"
                :carregando="salvando"
                texto-carregando="Salvando..."
              />
            </div>
          </form>
        </template>
      </uspCartao>
    </div>
  </section>
</template>

<script>
import { formataProcesso } from '@/utils'
import { mapActions, mapGetters } from 'vuex'
import { Actions, Getters } from '@/processos/store/types';
import ProcessosAvisoTutorial from '../components/ProcessosAvisoTutorial.vue';


export default {
    name: 'ProcessosForm',
    components: { ProcessosAvisoTutorial },
    data() {
        return {
            // Props gerais
            carregando: true,
            erro: "",
            erros: {},
            codasuInicial: '',
            salvando: false,
            validado: false,
            // Props do modelo
            nominr: "",
            titpro: "",
            codasu: "",
            sgletaabt: "",
            cplasu: "",
            numpro: this.$route.params.numpro,
        };
    },
    computed: {
        ...mapGetters({
            assuntos: Getters.ESTRUTURA.ASSUNTOS,
            getcplobg: Getters.ESTRUTURA.ASSUNTO_CPLOBG,
            estacoes: Getters.ESTRUTURA.ESTACOES_USUARIO,
            dados: Getters.PROCESSOS.DADOS
        }),
        cplasu_label() {
            return `Complemento (${this.cplobg ? "obrigatório" : "opcional"})`;
        },
        cplobg() {
            return this.getcplobg(this.codasu) === "S";
        },
        numprofmt() {
            return formataProcesso(this.numpro);
        },
        titulo() {
            return this.numpro ? "Alterando dados do Processo " + this.numprofmt : "Novo Processo";
        },
        cplasu_filtrado() {
            return (this.cplasu).replace(/[\t\n\r]*/g, '')
        }
    },
    async mounted() {
        const self = this;
        self.carregando = true
        self.codasuInicial = ''
        if (self.assuntos.length === 0) {
            await self.listarAssuntos();
        }
        if (self.estacoes.length === 0) {
            await self.listarEstacoes();
        }
        if(self.numpro){
            if(!self.dados?.numpro){
                await self.obterProcesso({ numpro: self.numpro })
            }
            self.nominr = self.dados.nominr
            self.titpro = self.dados.titpro
            self.codasu = self.dados.assunto.codasu
            self.codasuInicial = self.assuntos.find(a => a.codasu === self.codasu).getTexto()
            self.sgletaabt = self.dados.abertura.estacao.sgleta
            self.cplasu = self.dados.cplasu
        }
        self.carregando = false;
    },
    methods: {
        ...mapActions({
            listarAssuntos: Actions.ESTRUTURA.ASSUNTOS,
            listarEstacoes: Actions.ESTRUTURA.ESTACOES_USUARIO,
            obterProcesso: Actions.PROCESSOS.CARREGAR_DADOS,
            salvarProcesso: Actions.PROCESSOS.SALVAR
        }),
        limpaErro() {
            this.erro = "";
        },
        salvar() {
            const self = this;
            self.validado = false;
            if(self.validar()){
              self.salvando = true;
              self.salvarProcesso({
                  nominr: self.nominr,
                  titpro: self.titpro,
                  codasu: self.codasu,
                  sgletaabt: self.sgletaabt,
                  cplasu: self.cplasu_filtrado,
                  numpro: self.numpro
              }).then((dados) => {
                  self.$router.push(`/processos/processos-digitais/${dados.numpro || self.numpro}`);
              }).catch(error => {
                  if (error.erro) {
                      self.erro = error.erro;
                  }
              }).finally(() => {
                  self.salvando = false;
              })
            }
        },
        validar(){
          const self = this;
          const erros = {};
          if(!self.nominr){
            erros.nominr = 'Interessado é de preenchimento obrigatório!';
          }
          if(!self.titpro){
            erros.titpro = 'Documento base é de preenchimento obrigatório!';
          }
          if(!self.sgletaabt){
            erros.sgletaabt = 'Setor de Entrada é de preenchimento obrigatório!';
          }
          if(!self.codasu){
            erros.codasu = 'Assunto é de preenchimento obrigatório!';
          }
          if(!self.cplasu && self.cplobg){
            erros.cplasu = 'Complemento é de preenchimento obrigatório!';
          }
          self.erros = erros;
          self.validado = true;
          return Object.keys(erros).length === 0;
        }
    },

}
</script>